import { APP_INITIALIZER, NgModule } from '@angular/core';

import { initializeOfflineService, OfflineService } from './services/offline/offline.service';
import { InitRequestSyncService, RequestSyncService } from './services/service-worker/request-sync/request-sync.service';



@NgModule({
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: initializeOfflineService,
      deps: [ OfflineService ],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: InitRequestSyncService,
      deps: [ RequestSyncService ],
      multi: true
    }
  ]
})
export class OfflineModule { }
