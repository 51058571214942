import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit
} from '@angular/core';
import {
  AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  map, switchMap, tap
} from 'rxjs/operators';
import { SAMLDataService } from '~shared/services/apiSAMLController';
import { AppState } from '~core/states/app/app.state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UI_BUILD_VERSION } from '~core/constants/version';
import { STILFacilityDataService } from '~shared/services/apiSTILFacilityController';
import { ToastrService } from 'ngx-toastr';
import { find } from 'lodash';
import { AuthService } from '~auth/services/auth.service';



@UntilDestroy()
@Component({
  selector: 'app-sso-page',
  templateUrl: './sso-page.component.html',
  styleUrls: [ './sso-page.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class SsoPageComponent implements OnInit {

  samlToken: string;
  samlResponse: string;
  loginForm: UntypedFormGroup;
  uiVersion = UI_BUILD_VERSION;
  connectionStringNames: any[];
  facilities: any[];
  idpSettings: any;
  isSSO = false;
  baseURL: string;
  loading = false;
  returnUrl: string;
  showInvalidCredsMsg = false;
  submitted = false;
  urlReferer: string;


  get connectionStringNameCtrl(): AbstractControl {
    return this.loginForm.get('connectionStringName');
  }

  get facilityIdCtrl(): AbstractControl {
    return this.loginForm.get('facilityId');
  }

  constructor(
    private route: ActivatedRoute,
    private appState: AppState,
    private facDataSvc: STILFacilityDataService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private samlDataSvc: SAMLDataService,
    private authSvc: AuthService,
    private formBuilder: UntypedFormBuilder
  ) {}


  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        this.samlToken = params.Token;
      }
      );

    this.loginForm = this.formBuilder.group({
      connectionStringName: [ '', Validators.required ],
      facilityId: [ '', Validators.required ]
    });

    this.connectionStringNameCtrl.valueChanges
      .pipe(
        untilDestroyed(this),
        switchMap((connectionStringName: string) => this.facDataSvc.getRecords(true, connectionStringName))
      )
      .subscribe((facilities) => {
        this.facilities = facilities;

        if (facilities.length) {
          const defaultFacility = facilities[0];
          this.loginForm.patchValue({ facilityId: defaultFacility.ID });
          this.cdr.markForCheck();
        } else {
          this.toastr.error('No Facilities were found. Please try another Connection String Name.');
        }
      });

    this.route.data
      .pipe(untilDestroyed(this))
      .subscribe(({
        loginPageData: {
          connectionStringNames, defaultConnectionStringName, defaultFacility, facilities, idpSettings
        }
      }) => {
        this.connectionStringNames = connectionStringNames;
        this.facilities = facilities;
        this.idpSettings = idpSettings;
        this.isSSO = this.idpSettings.UseSSO === 'true';
        this.baseURL = this.idpSettings.SPAPIURL;

        this.loginForm.patchValue({
          connectionStringName: defaultConnectionStringName,
          facilityId: +defaultFacility.ID
        }, { emitEvent: false });
      });

    this.urlReferer = document.referrer;
    console.log(this.urlReferer);
  }

  private resetLoginForm(): void {
    this.submitted = false;
    this.loading = false;
    this.showInvalidCredsMsg = true;
    this.cdr.markForCheck();
  }

  onSubmit() {
    const {
      connectionStringName, facilityId
    } = this.loginForm.value;
    this.submitted = true;
    this.loading = true;

    const selectedFacility = find(this.facilities, { ID: +facilityId });

    if (this.loginForm.invalid || !this.samlToken) {
      this.toastr.error('Invalid user for this facility');
      this.loading = false;
      return;
    }

    this.samlDataSvc.ssoLogin(connectionStringName, facilityId, this.samlToken)
      .subscribe(authObj => {
        if (authObj) {
          this.appState.set('facility', selectedFacility);
          this.showInvalidCredsMsg = false;
          const { user } = authObj;
          this.appState.set('user', user);
          this.authSvc.setSession(authObj);
          this.authSvc.redirectUser();
        } else {
          this.resetLoginForm();
        }
      });
  }


}
