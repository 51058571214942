<div class="flex flex-row flex-wrap w-full">
  <a
    *ngIf="link?.Enabled"
    [routerLink]="link?.Enabled ? link?.URL : '/'"
    routerLinkActive="selected"
    class="nav-link justify-start items-center w-full"
    [class.disabled]="!link?.Enabled"
  >
    <i
      [class]="link?.Icon?.length ? link.Icon : 'fas fa-circle-notch'"
      class="pl-4"
    ></i>

    <span
      *ngIf="isRecentPermitLink(); else textLink"
      class="nav-link-title pl-2"
    >
      {{ link?.Name }}
    </span>

    <ng-template #textLink>
      <span class="nav-link-title pl-2">
        {{ [link?.ItemType, 'LEFT_NAV', link?.Name] | translate }}
      </span>
    </ng-template>
  </a>

  <div
    *ngIf="isLastSectionLink(link?.Name)"
    class="divider flex flex-row w-full"
  ></div>
</div>
