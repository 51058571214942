import { Injectable } from '@angular/core';
import { isEmpty } from 'lodash';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import {
  combineLatest, Observable, of
} from 'rxjs';
import {
  distinctUntilChanged, filter, pluck, switchMap, take, tap
} from 'rxjs/operators';
import { NetworkState } from '~auth/states/network-state/network.state';
import { AppState, AppStateKey } from '~core/states/app/app.state';
import { User } from '~shared/models/user.model';
import { STILUserDataService } from '~shared/services/apiSTILUserController';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(
    private indexedDB: NgxIndexedDBService,
    private networkState: NetworkState,
    private state: AppState,
    private userDataSvc: STILUserDataService
  ) { }

  initAppState(): Observable<User> {
    /* Sets facilityId in app state when facility changes */
    this.state.facility$
      .pipe(
        tap(({ Name }) => this.state.set('facilityName', Name)),
        pluck('ID'),
        distinctUntilChanged()
      )
      .subscribe(ID => {
        this.state.set('facilityId', +ID);
        localStorage.setItem('lastFacilityId', ID);
      });

    /* Updates user's ParentID in app state when facilityId changes */
    return combineLatest([ this.state.facility$, this.state.user$ ])
      .pipe(
        filter(([ facility, user ]) => !isEmpty(facility) && !isEmpty(user)),
        switchMap(([ facility, user ]) => {
          if (+user.ParentID !== +facility.Company_ParentID) {
            return this.userDataSvc.edit(user);
          }

          return of(user);
        }),
        take(1)
      );
  }

  async loadsessionStorage(): Promise<void> {
    for (let key of [ 'facility', 'user' ]) {
      let value = sessionStorage.getItem(key);
      console.log(key, value);

      if (!this.networkState.get('isOnline') && !value) {
        const cachedItem: any = await this.indexedDB.getByIndex('appState', 'key', key).toPromise();
        value = cachedItem?.value;
      }

      if (value) {
        this.state.set(key as AppStateKey, JSON.parse(value));
      }
    }
  }
}

export function InitializeAppState(appSvc: AppService): () => void {
  return () => appSvc.loadsessionStorage();
}

