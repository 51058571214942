import { Injectable } from '@angular/core';
import {
  State, StateBehavior, StateReplay 
} from '@devlime/ngx-state';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import {
  Dictionary, find, get 
} from 'lodash';
import { Observable } from 'rxjs';
import { FacilitySetting } from '~shared/models/facility-setting.model';

import { ActionStatus } from '../../models/action-status.model';
import { Action } from '../../models/action.model';
import { Permit } from '../../models/permit.model';
import { Status } from '../../models/status.model';

export interface CurrentStatusIds {
  statusId?: number;
  actionId?: number;
  actionLinkId?: number;
  actionStatusId?: number;
}

interface PermitEditStateProps {
  actionLinkId: number;
  allSettings: FacilitySetting[];
  allStatuses: Array<Status|Action|ActionStatus>;
  currentSettings: Dictionary<any>;
  currentStatus: Status;
  currentStatusIds: CurrentStatusIds;
  currentTab: string;
  defaultTab: string;
  headerFieldsEnabled: Dictionary<any>;
  permit: Permit;
  permitStatuses: Status[];
  readOnly: boolean;
  selectedAddonId: number;
  settings: any[];
  statusId: number;
  varietyTypeSettings: any[];
}

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class PermitEditState extends State<PermitEditStateProps> {

  @StateReplay<PermitEditState, number>()
  readonly actionLinkId: number;
  readonly actionLinkId$: Observable<number>;

  @StateReplay<PermitEditState, FacilitySetting[]>()
  readonly allSettings: FacilitySetting[];
  readonly allSettings$: Observable<FacilitySetting[]>;

  @StateReplay<PermitEditState, Array<Status|Action|ActionStatus>>()
  readonly allStatuses: Array<Status|Action|ActionStatus>;
  readonly allStatuses$: Observable<Array<Status|Action|ActionStatus>>;

  @StateReplay<PermitEditState, Dictionary<any>>()
  readonly currentSettings: Dictionary<any>;
  readonly currentSettings$: Observable<Dictionary<any>>;

  @StateBehavior<PermitEditState, CurrentStatusIds>({})
  readonly currentStatusIds: CurrentStatusIds;
  readonly currentStatusIds$: Observable<CurrentStatusIds>;

  @StateBehavior<PermitEditState, string>('')
  private currentTab: string;
  readonly currentTab$: Observable<string>;

  @StateReplay<PermitEditState, string>()
  private defaultTab: string;
  readonly defaultTab$: Observable<string>;

  @StateReplay<PermitEditState, string>()
  private headerFieldsEnabled: Dictionary<any>;
  readonly headerFieldsEnabled$: Observable<Dictionary<any>>;

  @StateReplay<PermitEditState, Permit>()
  readonly permit: Permit;
  readonly permit$: Observable<Permit>;

  @StateReplay<PermitEditState, any[]>()
  readonly settings: any[];
  readonly settings$: Observable<any[]>;

  @StateReplay<PermitEditState, Status[]>()
  readonly permitStatuses: Status[];
  readonly permitStatuses$: Observable<Status[]>;

  @StateReplay<PermitEditState, Status>()
  readonly currentStatus: Status;
  readonly currentStatus$: Observable<Status>;

  @StateBehavior<PermitEditState, boolean>(false)
  readonly readOnly: boolean;
  readonly readOnly$: Observable<boolean>;

  @StateBehavior<PermitEditState, number>(null)
  private selectedAddonId: number;
  readonly selectedAddonId$: Observable<number>;

  @StateReplay<PermitEditState, number>()
  readonly statusId: number;
  readonly statusId$: Observable<number>;

  @StateBehavior<PermitEditState, any[]>([])
  readonly varietyTypeSettings: any[];
  readonly varietyTypeSettings$: Observable<any[]>;

  constructor() {
    super();
  }

  init(): void {
    /* Set PermitEdit CurrentStatus when allStatuses are updated */
    this.allStatuses$
      .pipe(untilDestroyed(this))
      .subscribe(allStatuses => {
        let currentStatus = find(allStatuses, {
          ItemType: 'Status',
          StatusType: 'Current' 
        });

        if (this.get('readOnly')) {
          const statusId = get(this.get('currentStatusIds'), 'statusId');
          currentStatus = find(allStatuses, { ItemID: statusId });
        }

        this.set('currentStatus', currentStatus);
      });
  }


}

export function InitializePermitEditState(permitEditState: PermitEditState): () => void {
  return () => permitEditState.init();
}
