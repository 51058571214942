import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot, Resolve, Router
} from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { AuthState } from '~auth/states/auth.state';
import { AppService } from '~core/services/app/app.service';
import { FacilitySettingService } from '~core/services/facility-setting/facility-setting.service';
import { SidebarService } from '~core/services/sidebar.service';
import { PermissionsService } from '~permissions/services/permissions/permissions.service';
import { TranslationService } from '~translations/services';

@Injectable({
  providedIn: 'root'
})
export class AppResolve implements Resolve<Observable<void>> {

  constructor(
    private appSvc: AppService,
    private authState: AuthState,
    private facilitySettingSvc: FacilitySettingService,
    private permissionsSvc: PermissionsService,
    private router: Router,
    private sidebarSvc: SidebarService,
    private translationSvc: TranslationService
  ) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    if (this.authState.get('isLoggedIn')) {
      return this.appSvc.initAppState()
        .pipe(
          switchMap(() => forkJoin([
            this.facilitySettingSvc.initFacilityState(),
            this.permissionsSvc.initPermissions(),
            this.sidebarSvc.initSidebarState(),
            this.translationSvc.initTranslationsStateAsync()
          ]))
        );
    } else {
      this.router.navigateByUrl('/login');
    }
  }
}
