import { Injectable } from '@angular/core';
import { State, StateReplay } from '@devlime/ngx-state';
import { Observable } from 'rxjs';

import { Permit } from '../../../permit/models/permit.model';

interface DataGridStateProps {
  permits: Permit[];
}

@Injectable({
  providedIn: 'root'
})
export class DataGridState extends State<DataGridStateProps> {

  @StateReplay<DataGridState, any[]>()
  readonly permits: Permit[];
  readonly permits$: Observable<Permit[]>;

  constructor(
  ) {
    super();
  }
}
