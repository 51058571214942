import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SLIDE_IN_OUT_ANIMATION } from '~animations/slide-in-out.animation';
import { InactivityService } from '~auth/services/inactivity/inactivity.service';
import { NetworkState } from '~auth/states/network-state/network.state';

import { LayoutService } from '../../services/layout/layout.service';
import { AppState } from '../../states/app/app.state';
import { SidebarState } from '../../states/sidebar/sidebar.state';

@UntilDestroy()
@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: [ './layout.component.scss' ],
  animations: [ SLIDE_IN_OUT_ANIMATION ]
})
export class LayoutComponent implements OnInit {

  collapseRightNav: boolean;

  collapsedState$ = this.sidebarState.collapsed$
    .pipe(map(collapsed => (collapsed ? 'hide' : 'show')));

  isMobile: boolean;

  isOffline$: Observable<boolean> = this.networkState.isOnline$.pipe(map(isOnline => !isOnline));

  navigationEnd$ = this.router.events.pipe(filter(ev => ev instanceof NavigationEnd));

  showOfflineBanner = true;

  showTopbar$ = this.appState.showTopbar$;

  constructor(
    public appState: AppState,
    private inactivitySvc: InactivityService,
    private layoutService: LayoutService,
    private networkState: NetworkState,
    private sidebarState: SidebarState,
    private router: Router,
    private titleSvc: Title
  ) { }

  ngOnInit() {
    this.inactivitySvc.startWatch();

    this.networkState.isOnline$
      .pipe(untilDestroyed(this))
      .subscribe(isOnline => {
        console.log('isOnline', isOnline);
      });

    this.appState.user$
      .pipe(untilDestroyed(this))
      .subscribe(user => {
        let title = 'SafeTK';
        if (user?.UserName) {
          title += ` - ${user.UserName}`;
        }
        this.titleSvc.setTitle(title);
      });

    this.layoutService.isMobile$.pipe(
      untilDestroyed(this)
    ).subscribe(isMobile => {
      this.isMobile = isMobile;
      this.sidebarState.set('collapsed', isMobile);
      this.sidebarState.set('rightNavCollapsed', isMobile);
    })

    this.sidebarState.rightNavCollapsed$.pipe(
      untilDestroyed(this)
    ).subscribe((isRightCollapsed: boolean) => {
      this.collapseRightNav = isRightCollapsed;
    })

    this.navigationEnd$
      .pipe(
        untilDestroyed(this),
        filter(() => this.isMobile)
      ).subscribe(() => this.sidebarState.set('collapsed', true));
  }

  closeOfflineBanner() {
    this.showOfflineBanner = false;
  }
}
