import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginControllerService {

  constructor(
    private http: HttpClient
  ) { }

  login(connectionStringName: string, facilityID: number, userName: string, password: string): Observable<any> {
    const params = {
      connectionStringName,
      facilityID,
      userName,
      unencryptedPassword: password
    };
    return this.http.post<string>(`login`, params);
  }

  ssoLogin(connectionStringName: string, facilityID: number, samlToken: string): Observable<any> {
    const params = {
      connectionStringName,
      facilityID,
      samlToken
    };
    return this.http.post<string>(`ssoLogin`, params);
  }

  logout(): Observable<any> {
    return this.http.get<any>(`logout`);
  }

  refreshToken(refreshToken: string): Observable<any> {
    const params = { refreshToken };
    return this.http.post<string>(`tokenRefresh`, params);
  }
}
