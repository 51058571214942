
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { LoginControllerService } from './app-services/apiLoginAppController';
import {
  catchError, tap
} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SAMLDataService {
  constructor(
    private http: HttpClient,
    private loginSvc: LoginControllerService,
    private toastr: ToastrService
  ) {}

  getSettings(): Observable<any> {
    return this.http.get<any>(`saml/idpgetsettings`);
  }

  samlLogin(samlResponse: string): Observable<any> {
    const params = {
      samlResponse
    };
    return this.http.post<string>(`saml/samllogin`, params);
  }

  ssoLogin(connectionStringName: string, facilityId: number, samlToken: string): Observable<any> {
    console.info('Login call started...');
    return this.loginSvc.ssoLogin(connectionStringName, facilityId, samlToken)
      .pipe(
        tap(() => localStorage.setItem('lastConnectionStringName', connectionStringName)),
        catchError(error => {
          console.error(`Login Error: ${error?.message}`);
          this.toastr.error('Authorization Failed');
          return of(false);
        })
      );
  }
}
