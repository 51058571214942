import {
  NgModule, Pipe, PipeTransform 
} from '@angular/core';
import { ServerConnectionStatus } from '~shared/models/serverconnectionstatus.model';

@Pipe({
  name: 'serverConnectionStatus'
})
export class ServerConnectionStatusPipe implements PipeTransform {

  transform(status: ServerConnectionStatus): string {
    const serverStatusColors = {
      connected: 'greenyellow',
      disconnected: 'black',
      reconnecting: '#424242',
      slow: 'yellow',
      error: 'red',
      blink: 'lightgoldenrodyellow'
    };

    return serverStatusColors[status];
  }
}
