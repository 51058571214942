import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withCache } from '@ngneat/cashew';
import { ContextOptions } from '@ngneat/cashew/lib/cache-context';
import { Observable } from 'rxjs';
import { Translation } from '~translations/models/translation.model';


@Injectable({
  providedIn: 'root'
})
export class STIL_LanguageTranslationDataService {
  constructor(private http: HttpClient) {}

  create(translation) {
    const params = { ...translation };
    return this.http.post(`stilLanguageTranslation/create`, {}, { params: params as any });
  }
  edit(data) {
    const params = {
      ...data,
      languageTranslationID: data.ID
    };
    return this.http.put(`stilLanguageTranslation/edit`, {}, { params: params as any });
  }
  delete(languageTranslationID: number) {
    const params = { languageTranslationID };
    return this.http.delete(`stilLanguageTranslation/delete`, { params: params as any });
  }
  view(languageTranslationID: number) {
    const params = { languageTranslationID };
    return this.http.get(`stilLanguageTranslation/view`);
  }
  getID(parentId: number, itemType: string, formName: string, controlName: string) {
    const params = {
      parentId,
      itemType,
      formName,
      controlName
    };
    return this.http.get(`stilLanguageTranslation/getID`);
  }
  getRecordsForItemType(parentId: number, itemType: string) {
    const params = {
      parentId,
      itemType
    };
    return this.http.get(`stilLanguageTranslation/getRecordsForItemType`);
  }

  getAllForLanguageIDPermitTypeAndFormName(parentId: number, itemType: string, formName: string) {
    const params = {
      parentId,
      itemType,
      formName
    };
    return this.http.get(`stilLanguageTranslation/getRecordsForItemTypeAndFormName`);
  }

  getRecordsForParentID(parentID: number): Observable<Translation[]> {
    const params = { parentID };
    return this.http.get<Translation[]>(`stilLanguageTranslation/getRecordsForParentID`, {
      params,
      context: withCache()
    });
  }

  getRecordsForParentIDWithoutCache(parentID: number): Observable<Translation[]> {
    const params = { parentID };
    return this.http.get<Translation[]>(`stilLanguageTranslation/getRecordsForParentID`, { params: params as any });
  }

  initializeTranslations(languageID: number) {
    const params = {
      languageID
    };
    return this.http.get(`stilLanguageTranslation/initializeTranslations`, { params: params as any });
  }
}
