<div class="flex flex-row flex-wrap w-full">
  <h3
    mat-dialog-title
    class="w-full"
  >
    Reset Password
  </h3>

  <mat-dialog-content
    [class.no-show]="!passwordCtrl?.value"
    class="flex flex-row flex-wrap justify-center items-start w-full m-0 p-2"
    [formGroup]="passwordForm"
  >
    <label
      for="password"
      class="form-label w-full"
    >
      Current Password
    </label>
    <div class="input-group w-full mb-3">
      <input
        [type]="getInputType(showCurrentPassword)"
        name="currentPassword"
        formControlName="currentPassword"
        class="form-control"
      />
      <div class="input-group-append">
        <button
          class="show-btn btn btn-outline-secondary"
          type="button"
          (click)="toggleShowCurrentPassword()"
        >
          <i [class]="['fas', showCurrentPassword ? 'fa-eye-slash' : 'fa-eye']"></i>
        </button>
      </div>
    </div>

    <label
      for="password"
      class="form-label w-100"
    >
      New Password
    </label>
    <div class="input-group w-100 mb-3">
      <input
        [type]="getInputType(showPassword)"
        name="password"
        formControlName="password"
        class="form-control"
      />
      <div class="input-group-append">
        <button
          class="show-btn btn btn-outline-secondary"
          type="button"
          (click)="toggleShowPassword()"
        >
          <i [class]="['fas', showPassword ? 'fa-eye-slash' : 'fa-eye']"></i>
        </button>
      </div>
    </div>

    <label
      for="passwordVerify"
      class="form-label w-full"
    >
      Verify New Password
    </label>
    <div class="input-group mb-3">
      <input
        [type]="getInputType(showPasswordVerify)"
        name="passwordVerify"
        formControlName="passwordVerify"
        class="form-control"
      />
      <div class="input-group-append">
        <button
          class="show-btn btn btn-outline-secondary"
          type="button"
          (click)="toggleShowPasswordVerify()"
        >
          <i [class]="['fas', showPasswordVerify ? 'fa-eye-slash' : 'fa-eye']"></i>
        </button>
      </div>
    </div>

    <div
      id="password-criteria"
      class="flex flex-row flex-wrap justify-start items-center w-full"
    >
      <div
        class="criteria flex flex-row justify-start items-center p-2 w-full"
        [ngClass]="currentPassword.errors === null || !currentPassword.errors ? 'valid' : 'invalid'"
      >
        <mat-icon>
          {{ currentPassword.errors ? 'report' : 'check' }}
        </mat-icon>
        <div class="mr-4">Current Password Entered</div>
      </div>

      <div
        class="criteria flex flex-row justify-start items-center p-2 w-full"
        [ngClass]="passwordForm.errors === null || !passwordForm.errors['noMatch'] ? 'valid' : 'invalid'"
      >
        <mat-icon>{{ passwordForm.errors === null || !passwordForm.errors['noMatch'] ? 'check' : 'report' }}</mat-icon>
        <div class="mr-4">New Passwords Match</div>
      </div>

      <div
        class="criteria flex flex-row justify-start items-center p-2 w-full"
        [ngClass]="
          passwordCtrl.errors === null || !passwordCtrl.errors['patternsInvalid']?.lengthInvalid ? 'valid' : 'invalid'
        "
      >
        <mat-icon>
          {{
            passwordCtrl.errors === null || !passwordCtrl.errors['patternsInvalid']?.lengthInvalid ? 'check' : 'report'
          }}
        </mat-icon>
        <div class="mr-4">Minimum of 8 and Maximum of 35 characters in length</div>
      </div>

      <div
        class="criteria flex flex-row justify-start items-center p-2 w-full"
        [ngClass]="
          passwordCtrl.errors === null || !passwordCtrl.errors['patternsInvalid']?.caseInvalid ? 'valid' : 'invalid'
        "
      >
        <mat-icon>
          {{
            passwordCtrl.errors === null || !passwordCtrl.errors['patternsInvalid']?.caseInvalid ? 'check' : 'report'
          }}
        </mat-icon>
        <div class="mr-4">Contains both upper and lowercase letters (e.g. A-Z, a-z)</div>
      </div>

      <div
        class="criteria flex flex-row justify-start items-center p-2 w-full"
        [ngClass]="
          passwordCtrl.errors === null || !passwordCtrl.errors['patternsInvalid']?.numericInvalid ? 'valid' : 'invalid'
        "
      >
        <mat-icon>
          {{
            passwordCtrl.errors === null || !passwordCtrl.errors['patternsInvalid']?.numericInvalid ? 'check' : 'report'
          }}
        </mat-icon>
        <div class="mr-4">Contains at least one number (e.g. 0-9)</div>
      </div>

      <div
        class="criteria flex flex-row justify-start items-center p-2 w-full"
        [ngClass]="
          passwordCtrl.errors === null || !passwordCtrl.errors['patternsInvalid']?.specialCharInvalid
            ? 'valid'
            : 'invalid'
        "
      >
        <mat-icon>
          {{
            passwordCtrl.errors === null || !passwordCtrl.errors['patternsInvalid']?.specialCharInvalid
              ? 'check'
              : 'report'
          }}
        </mat-icon>
        <div class="mr-4">Contains at least one special character (e.g. ~!@#$%^&*()_-+=)</div>
      </div>

      <div
        class="criteria flex flex-row justify-start items-center p-2 w-full"
        [ngClass]="
          passwordCtrl.errors === null || !passwordCtrl.errors['patternsInvalid']?.caseInvalid ? 'valid' : 'invalid'
        "
      >
        <mat-icon>
          {{
            passwordCtrl.errors === null || !passwordCtrl.errors['patternsInvalid']?.caseInvalid ? 'check' : 'report'
          }}
        </mat-icon>
        <div class="mr-4">Contains both upper and lowercase letters (e.g. A-Z, a-z)</div>
      </div>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions class="flex flex-row justify-end items-center w-full">
    <button
      class="btn btn-sm btn-warning m-2"
      tabindex="-1"
      [mat-dialog-close]="false"
    >
      Cancel
    </button>
    <button
      class="btn btn-sm btn-primary m-2"
      tabindex="-1"
      (click)="resetPassword()"
      [disabled]="!passwordForm.valid"
    >
      Confirm
    </button>
  </mat-dialog-actions>
</div>
