import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit 
} from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import filter from 'lodash-es/filter';
import reduce from 'lodash-es/reduce';
import { SidebarState } from '~core/states/sidebar/sidebar.state';
import { PermissionsService } from '~permissions/services/permissions/permissions.service';
import { PermissionsState } from '~permissions/state/permissions.state';

import { ComponentSettingsService } from '../../services/component-settings.service';


@UntilDestroy()
@Component({
  selector: 'app-right-nav',
  templateUrl: './right-nav.component.html',
  styleUrls: [ './right-nav.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RightNavComponent implements OnInit {

  get routes(): any[] {
    return [
      {
        icon: 'tachometer-alt',
        name: 'Administrator',
        route: '/admin'
      },
      {
        icon: 'users-cog',
        name: 'Security',
        children: [
          {
            icon: 'user',
            name: 'User Manage',
            securitySetting: 'SecurityUser',
            route: '/security/user-manage',
            disabled: this.isRouteDisabled('securityUser')
          },
          {
            icon: 'users',
            name: 'Group Manage',
            securitySetting: 'SecurityGroup',
            route: '/security/group-manage',
            disabled: this.isRouteDisabled('securityGroup')
          },
          {
            icon: 'list',
            name: 'Security Lists',
            securitySetting: 'SecurityList',
            route: '/security/lists',
            disabled: this.isRouteDisabled('securityList')
          }
        ]
      },
      {
        icon: 'database',
        name: 'Asset',
        children: [
          {
            icon: 'sitemap',
            name: 'Asset Manage',
            securitySetting: 'AssetManage',
            route: '/asset/manage'
          },
          {
            icon: 'tags',
            name: 'Component Manage',
            securitySetting: 'ComponentManage',
            route: '/asset/component-manage'
          }
        ]
      },
      {
        icon: 'list',
        name: 'Master List',
        securitySetting: 'MasterList',
        children: [
          {
            icon: 'atlas',
            name: 'Master List Manage',
            securitySetting: 'MasterListManage',
            route: '/masterlist/manage'
          },
          {
            icon: 'sort-numeric-up-alt',
            name: 'Prefix Manage',
            securitySetting: 'PrefixManage',
            route: '/masterlist/prefix-manage'
          },
          {
            icon: 'question-circle',
            name: 'Question Manage',
            securitySetting: 'QuestionManage',
            route: '/question-manager'
          },
          {
            icon: 'bullhorn',
            name: 'Announcement Manage',
            securitySetting: 'AnnouncementManage',
            route: '/announcement-manager'
          },
          {
            icon: 'tags',
            name: 'Tag Report Manage',
            securitySetting: 'TagReportManage',
            route: '/configuration/tag-report-links'
          }
        ]
      },
      {
        icon: 'print',
        name: 'Print Manage',
        securitySetting: 'PrintManage',
        children: [
          {
            icon: 'list',
            name: 'Print List',
            route: '/printlist',
            securitySetting: 'PrintList',
            disabled: this.isRouteDisabled('printList')
          },
          {
            icon: 'link',
            name: 'Print Settings',
            route: '/printlist/print-settings',
            securitySetting: 'PrintSettings',
            disabled: this.isRouteDisabled('printSettings')
          },
          {
            icon: 'receipt',
            name: 'Report Settings',
            route: '/report/report-settings',
            securitySetting: 'PrintReportSettings',
            disabled: this.isRouteDisabled('printReportSettings')
          }
        ]
      },
      {
        icon: 'language',
        name: 'Translations',
        route: 'translations'
      },
      {
        icon: 'toolbox',
        name: 'Settings',
        children: [
          {
            icon: 'industry',
            name: 'Facility Settings',
            route: '/facility-settings',
            securitySetting: 'FacilitySettings'
          },
          {
            icon: 'industry',
            name: 'Settings Table',
            securitySetting: 'SettingsTable',
            route: '/admin/settings'
          }
        ]
      },
      {
        icon: 'network-wired',
        name: 'SafeTKonnect',
        securitySetting: 'SafeTKonnect',
        route: '/admin/safetkonnect'
      }
    ];
  }

  translations;
  permissionsSet = false;
  enabledRoutes = [];

  constructor(
    private cdr: ChangeDetectorRef,
    private sidebarState: SidebarState,
    private permissionsSvc: PermissionsService,
    private permissionsState: PermissionsState,
    private compSettingsSvc: ComponentSettingsService,
    private router: Router
  ) { }

  ngOnInit() {
    this.sidebarState.rightNavEnabled$
      .pipe(untilDestroyed(this))
      .subscribe(enabled => {
        this.permissionsSet = false;

        this.enabledRoutes = reduce(this.routes, (routes, route) => {
          let isEnabled = enabled[route?.securitySetting ?? route?.name];

          if (isEnabled) {

            if (route?.children?.length) {
              route.children = filter(route.children, child => enabled[child?.securitySetting ?? child?.name]);
            }

            routes.push(route);

          }

          return routes;
        }, []);

        this.cdr.markForCheck();
      });
  }

  navigateTo(route) {
    if (route !== undefined) {
      this.router.navigateByUrl(route);
    }
  }

  showSettings() {
    this.compSettingsSvc.toggleSettings();
  }

  isRouteDisabled(route) {
    if (!this.permissionsSet || !this.permissionsState.activePermissions) {
      this.permissionsSvc.getActivePermissions();
      this.permissionsSet = true;
    }

    return !this.permissionsSvc.isEnabled(0, [ 'rightNav',route ]);
  }

}
