import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import {
  OWL_DATE_TIME_FORMATS, OwlDateTimeModule, OwlNativeDateTimeModule
} from '@danielmoncada/angular-datetime-picker';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DateModule } from '~date/date.module';
import { PermissionControlDirective } from '~permissions/directives/permission-control/permission-control.directive';
import {
  PermissionFormControlDirective
} from '~permissions/directives/permission-form-control/permission-form-control.directive';
import { PermissionsModule } from '~permissions/permissions.module';

import { ConfirmationModule } from '../confirmation/confirmation.module';
import { AssociatedUserComponent } from './associated-user/associated-user.component';
import { DateIconComponent } from './components/date-icon/date-icon.component';
import { TabTableComponent } from './components/tab-table/tab-table.component';
import { UserIconComponent } from './components/user-icon/user-icon.component';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { DataGridEditorComponent } from './data-grid-editor/data-grid-editor.component';
import { DataGridComponent } from './data-grid/data-grid.component';
import { DataListComponent } from './data-list/data-list.component';
import { DatagridReorderOverlayComponent } from './datagrid-reorder-overlay/datagrid-reorder-overlay.component';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { StopClickPropagationDirective } from './directives/stop-click-propagation.directive';
import { TranslateDirective } from './directives/translate/translate.directive';
import { ImportDialogModule } from './import-dialog/import-dialog.module';
import { InputSpinnerComponent } from './input-spinner/input-spinner.component';
import { LinkGridPrinterComponent } from './link-grid-printer/link-grid-printer.component';
import { LinkGridComponent } from './link-grid/link-grid.component';
import { AddModalComponent } from './modals/add-modal/add-modal.component';
import { ColumnSettingsModalComponent } from './modals/column-settings-modal/column-settings-modal.component';
import { DatagridDialogComponent } from './modals/datagrid-dialog/datagrid-dialog.component';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { HighlightPipe } from './pipes/highlight.pipe';
import { ServerConnectionStatusPipe } from './pipes/serverconnectionstatus.pipe';
import { StartCasePipeModule } from './pipes/start-case.pipe';
import { TranslatePipeModule } from './pipes/translate.pipe';
import { ReorderMarkerComponent } from './reorder-marker/reorder-marker.component';
import { DataTableEditorComponent } from './safetk-data-table/components/data-table-editor/data-table-editor.component';
import { DataTableComponent } from './safetk-data-table/components/data-table/data-table.component';
import { MatButtonModule } from '@angular/material/button';



export const MY_NATIVE_FORMATS = {
  fullPickerInput: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h23'
  },
  datePickerInput: {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  },
  timePickerInput: {
    hour: 'numeric',
    minute: 'numeric'
  },
  monthYearLabel: {
    year: 'numeric',
    month: 'short'
  },
  dateA11yLabel: {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  },
  monthYearA11yLabel: {
    year: 'numeric',
    month: 'long'
  }
};

@NgModule({
  imports: [
    CommonModule,
    ConfirmationModule,
    DateModule,
    DragDropModule,
    FormsModule,
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTabsModule,
    ModalModule.forRoot(),
    NgxDatatableModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    PermissionFormControlDirective,
    PermissionControlDirective,
    PermissionsModule,
    ReactiveFormsModule,
    RouterModule,
    StartCasePipeModule,
    TooltipModule.forRoot(),
    TranslatePipeModule,
    TypeaheadModule,
    ImportDialogModule
  ],
  declarations: [
    AddModalComponent,
    AssociatedUserComponent,
    ColumnSettingsModalComponent,
    ContextMenuComponent,
    DataGridComponent,
    DataGridEditorComponent,
    DataListComponent,
    DataTableComponent,
    DataTableEditorComponent,
    DatagridDialogComponent,
    DatagridReorderOverlayComponent,
    DateIconComponent,
    DatePickerComponent,
    EllipsisPipe,
    HighlightPipe,
    InputSpinnerComponent,
    LinkGridComponent,
    LinkGridPrinterComponent,
    LinkGridPrinterComponent,
    ReorderMarkerComponent,
    TabTableComponent,
    TranslateDirective,
    UserIconComponent,
    ServerConnectionStatusPipe,
    StopClickPropagationDirective
  ],
  exports: [
    AssociatedUserComponent,
    CommonModule,
    ContextMenuComponent,
    DataGridComponent,
    DataListComponent,
    DataTableComponent,
    DataTableEditorComponent,
    DatagridDialogComponent,
    DateIconComponent,
    DatePickerComponent,
    EllipsisPipe,
    HighlightPipe,
    InputSpinnerComponent,
    LinkGridComponent,
    LinkGridPrinterComponent,
    MatIconModule,
    MatSelectModule,
    MatTabsModule,
    StartCasePipeModule,
    TabTableComponent,
    TooltipModule,
    TranslateDirective,
    TranslatePipeModule,
    UserIconComponent,
    ServerConnectionStatusPipe,
    StopClickPropagationDirective
  ],
  providers: [
    {
      provide: OWL_DATE_TIME_FORMATS,
      useValue: MY_NATIVE_FORMATS
    }
  ]
})
export class SharedModule {}
