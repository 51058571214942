import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { withCache } from '@ngneat/cashew';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class MasterListDataService {
  constructor(private http: HttpClient) {}

  create(params) {
    return this.http.post(`masterList/create`, {}, { params: params as any });
  }

  edit(params) {
    return this.http.put(`masterList/edit`, {}, { params });
  }

  delete(masterListID: number, TableName) {
    const params = {
      masterListID,
      TableName
    };
    return this.http.delete(`masterList/delete`, { params: params as any });
  }

  view(tableName: string, masterListID: number) {
    const params = {
      tableName,
      masterListID
    };
    return this.http.get(`masterList/view`, { params: params as any });
  }

  getID(tableName: string, parentID: number, itemType: string, name: string) {
    const params = {
      tableName,
      parentID,
      itemType,
      name
    };
    return this.http.get(`masterList/getID`, { params: params as any });
  }

  getRecordsForItemType(
    tableName: string,
    parentID: number,
    itemType: any,
    activeRecord: boolean,
    isMasterList: boolean
  ): Observable<any[]> {
    const params = {
      tableName,
      parentID,
      itemType,
      activeRecord,
      isMasterList
    };
    return this.http.get<any[]>(`masterList/getRecordsForItemType`, {
      params,
      context: withCache()
    });
  }

  getRecordsForItemTypeWithoutCache(
    tableName: string,
    parentID: number,
    itemType: any,
    activeRecord: boolean,
    isMasterList: boolean
  ): Observable<any[]> {
    const params = {
      tableName,
      parentID,
      itemType,
      activeRecord,
      isMasterList
    };
    return this.http.get<any[]>(`masterList/getRecordsForItemType`, { params: params as any });
  }

  reorderItem(tableName, parentID, id, newDisplayOrder) {
    const params = {
      TableName: tableName,
      parentID,
      id,
      newDisplayOrder
    };
    return this.http.put('masterlist/displayOrder_ReorderItem', {}, { params: params as any });
  }

  getRecordsForParentID(tableName: string, parentID: number, activeRecord: boolean, isMasterList: boolean): Observable<any[]> {
    const params = {
      tableName,
      parentID,
      activeRecord,
      isMasterList
    };
    return this.http.get<any[]>(`masterList/getRecordsForParentID`, {
      params,
      context: withCache()
    });
  }

}
