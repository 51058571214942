import { APP_INITIALIZER, NgModule } from '@angular/core';

import { DateService, InitializeDateService } from './date-service/date.service';
import { DisplayDatePipe } from './display-date-pipe/display-date.pipe';

@NgModule({
  declarations: [ DisplayDatePipe ],
  exports: [ DisplayDatePipe ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: InitializeDateService,
      deps: [ DateService ],
      multi: true
    }
  ]
})
export class DateModule { }
