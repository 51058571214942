<div
  (click)="groupClick.emit(group)"
  class="nav-block p-2 flex flex-row justify-start items-center w-full"
  [class.selected]="isActive"
>
  <i [class]="(group?.Icon?.length ? group.Icon : 'fas fa-circle-notch') + ' w-[25px]'"></i>

  <span class="nav-block-title p-1 grow">
    {{ group?.Name }}
  </span>
  <i [class]="(isOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down') + ' w-[25px]'"></i>
</div>

<div
  *ngIf="isOpen"
  class="flex flex-row flex-wrap"
>
  <app-nav-link
    *ngFor="let link of group?.links"
    [link]="link"
  ></app-nav-link>
</div>
