<!-- Default Text Input -->
<ng-container [ngSwitch]="config?.controlType">
  <mat-form-field *ngSwitchCase="'text'">
    <input
      type="text"
      matInput
      class="form-control"
      [disabled]="config?.uneditable"
      [value]="getFieldValue()"
      [ngStyle]="getStyle()"
      placeholder="{{ column.name }}"
      (keyup)="updateNewItem($event.target.value, column.prop)"
    />
  </mat-form-field>

  <!-- Check Box Input  -->
  <div
    *ngSwitchCase="'checkbox'"
    class="mt-1"
  >
    <mat-checkbox
      [checked]="value"
      [disabled]="config?.uneditable"
      (change)="updateNewItem($event.checked, column.prop)"
    >
      {{ column.name }}
    </mat-checkbox>
  </div>

  <!-- Combobox Input -->
  <mat-form-field *ngSwitchCase="'combobox'">
    <input
      type="text"
      matInput
      class="form-control"
      [disabled]="config?.uneditable"
      [value]="getFieldValue()"
      [ngStyle]="getStyle()"
      placeholder="{{ column.prop }}"
      (keyup)="updateNewItem($event.target.value, column.prop)"
      [matAutocomplete]="auto"
    />

    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="updateNewItem($event.option.value, column.prop)"
    >
      <mat-option
        *ngFor="let option of config.options | async"
        [value]="option[config.referenceProp]"
      >
        {{ option[config.referenceProp] }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <!-- Select Input -->
  <mat-form-field *ngSwitchCase="'select'">
    <mat-select
      style="margin: 0px auto; width: 100%"
      [disabled]="config?.uneditable"
      placeholder="{{ column.name }}"
      [value]="value"
      (selectionChange)="updateNewItem($event.value, column.prop)"
    >
      <mat-option
        *ngFor="let option of config.options | async"
        [value]="option[config.referenceProp]"
      >
        {{ option[config.referenceProp] }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
