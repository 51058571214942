import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { Observable } from 'rxjs';
import { AppState } from '~core/states/app/app.state';


@Injectable({
  providedIn: 'root'
})
export class AnnouncementsService {

  constructor(
    private appState: AppState,
    private http: HttpClient,
    private toast: HotToastService
  ) {}

  create(announcement: any): Observable<any> {
    const params = new HttpParams({
      fromObject: {
        TableName: 'Announcement'
      }
    });

    return this.http.post(`announcement/create`, {
      ...announcement,
      TableName: 'Announcement',
      ParentID: this.appState.get("facilityId") 
    }, { params })
      .pipe(
        this.toast.observe({
          loading: `Creating  Announcement`,
          success: ` Announcement Created`,
          error: `Failed to Create  Announcement`
        })
      );
  }

  edit(announcement: any) {
    const params = new HttpParams({
      fromObject: {
        TableName: 'Announcement'
      }
    });

    return this.http.put(`announcement/edit`, { ...announcement }, { params })
      .pipe(
        this.toast.observe({
          loading: `Updating Announcement`,
          success: `Announcement Updated`,
          error: `Failed to Update Announcement`
        })
      );
  }

  delete(announcementID: number) {
    const params = new HttpParams({
      fromObject: {
        TableName: `Announcement`,
        AnnouncementID: `${announcementID}`
      }
    });

    return this.http.delete(`announcement/delete`, { params })
      .pipe(
        this.toast.observe({
          loading: `Deleting Announcement`,
          success: `Announcement Deleted`,
          error: `Failed to Delete Announcement`
        })
      );
  }

  view(announcementId: number): Observable<any>  {
    const params = new HttpParams({
      fromObject: {
        TableName: `Announcement`,
        AnnouncementID: `${announcementId}`
      }
    });

    return this.http.get(`announcement/view`, { params });
  }

  getID(tableName: string, parentID: number, itemType: string, announcementText: string) {
    const params = {
      tableName,
      parentID,
      itemType,
      announcementText
    };
    return this.http.get(`announcement/getID`);
  }

  getRecordsForParentID(tableName: string, parentID: number, activeRecord: boolean) {
    const params = {
      tableName,
      parentID,
      activeRecord
    };
    return this.http.get(`announcement/getRecordsForParentID`);
  }

  getRecordsForParentIDAndItemType(tableName: string, parentID: number, itemType: string, activeRecord: boolean) {
    const params = {
      tableName,
      parentID,
      itemType,
      activeRecord
    };
    return this.http.get(`announcement/getRecordsForParentIDAndItemType`);
  }

  getAnnouncements(): Observable<any[]> {
    const params = new HttpParams({
      fromObject: {
        TableName: `Announcement`,
        ParentID: `${this.appState.get('facilityId')}`
      }
    });

    return this.http.get<any[]>('announcement/getRecordsForParentID', { params });
  }

  getAnnouncementsForToday(): Observable<any[]> {
    const params = new HttpParams({
      fromObject: {
        TableName: `Announcement`,
        ParentID: `${this.appState.get('facilityId')}`
      }
    });

    return this.http.get<any[]>('announcement/getCurrentRecords', { params });
  }
}

