<div class="stil-header pl-2 flex flex-row w-full">
  <div class="flex flex-row flex-nowrap justify-start items-center w-full">
    <div
      *subscribe="collapsed$ as collapsed"
      (click)="toggleSidebar()"
      class="p-2"
    >
      <i
        [@flipAround]="collapsed ? 'right' : 'left'"
        class="collapse-btn fas fa-angle-double-right"
        aria-hidden="false"
        aria-label="Toggle Menu"
      ></i>
    </div>

    <div
      id="facility-title"
      class="flex flex-row no-wrap justify-between items-center mr-4"
    >
      <span
        class="app-title lead mr-2"
        routerLink="/"
      >
        SafeTK
        <sup>®</sup>
      </span>

      <span class="plant-title">
        {{ facilityName$ | async | ellipsis: 25 }}
      </span>
    </div>

    <div class="flex flex-row flex-nowrap justify-start items-center w-full">
      <ng-container *ngIf="activePermitType$ | async as permitType">
        <i
          [class]="permitType | appPermitTypeIcon"
          [tooltip]="[permitType, 'LEFT_NAV', permitType] | translate"
          placement="bottom"
        ></i>
      </ng-container>

      <ng-container *ngIf="pageTitle$ | async as pageTitle">
        <mat-icon class="mx-1">chevron_right</mat-icon>
        {{ pageTitle }}
      </ng-container>

      <ng-container *ngIf="childPageTitle$ | async as childPageTitle">
        <mat-icon class="mx-1">chevron_right</mat-icon>
        {{ childPageTitle }}
      </ng-container>
    </div>

    <button
      *ngIf="isOffline"
      mat-icon-button
      class="mx-2 rounded-circle bg-warning"
      tooltip="You are currently working offline!"
    >
      <mat-icon id="offline-icon">cloud_off</mat-icon>
    </button>

    <app-user-menu-icon></app-user-menu-icon>

    <ng-container *ngIf="serverConnectionStatusIconEnabled">
      <div
        *subscribe="serverConnectionStatus$ as status"
        class="btn-group mr-2"
      >
        <button
          (click)="broadcastButton(status, $event)"
          class="bg-transparent p-0 border-0"
          [tooltip]="'Server status ' + status"
        >
          <span
            id="icon"
            class="trigger"
          >
            <i
              class="fas fa-server"
              [style.color]="status | serverConnectionStatus"
              aria-hidden="true"
            ></i>
          </span>
        </button>
      </div>
    </ng-container>

    <div [ngClass]="serverConnectionStatusIconEnabled ? 'w-[5px]' : 'w-[20px]'"></div>
  </div>
</div>
