import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-column-settings-modal',
  templateUrl: './column-settings-modal.component.html',
  styleUrls: [ './column-settings-modal.component.scss' ]
})
export class ColumnSettingsModalComponent   {
  columns = [];
  allColumns = [];


  constructor(public bsModalRef: BsModalRef) { }

  toggle(col) {
    const isChecked = this.isChecked(col);

    if (isChecked) {
      this.columns = this.columns.filter(c => {
        return c.name !== col.name;
      });
    } else {
      this.columns = [ ...this.columns, col ];
    }
  }

  isChecked(col) {
    return (
      this.columns.find(c => {
        return c.name === col.name;
      }) !== undefined
    );
  }
}
