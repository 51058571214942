import '@angular/compiler';

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

Quill.register('modules/imageResize', ImageResize);

function _window(): any {
  return window;
}

_window().Quill = Quill;
_window().quill = Quill;

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
