import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HotToastService } from '@ngneat/hot-toast';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { forkJoin, Observable } from 'rxjs';
import {
  filter, switchMap, tap 
} from 'rxjs/operators';
import { NetworkState } from '~auth/states/network-state/network.state';

export interface OfflineRequest {
  id?: number;
  url: string;
  method: string;
  body?: any;
  requestTime?: number;
}

@Injectable({
  providedIn: 'root'
})
export class RequestSyncService {

  constructor(
    private indexedDB: NgxIndexedDBService,
    private networkState: NetworkState,
    private toast: HotToastService,
    private http: HttpClient
  ) { }

  addRequest(request: OfflineRequest): Observable<any> {
    if (!request.requestTime) {
      request.requestTime = Date.now();
    }

    return this.indexedDB.add('requests', request)
      .pipe(tap(() => this.toast.success('Request added to queue')));
  }

  syncRequests() {

  }

  init(): void {
    this.networkState.isOnline$
      .pipe(
        filter(isOnline => !!isOnline),
        switchMap(() => this.indexedDB.getAll('requests')),
        filter((requests: OfflineRequest[]) => !!requests.length),
        switchMap(requests => {
          return forkJoin(
            requests.map((request: OfflineRequest) => {
              return this.http.request(request.method, request.url, { body: request?.body }).pipe(
                switchMap(() => this.indexedDB.delete('requests', request.id))
              );
            })
          ).pipe(
            this.toast.observe({
              loading: 'Syncing offline requests',
              success: 'Offline requests synced',
              error: 'Error syncing offline requests'
            })
          );
        })
      )
      .subscribe(requests => {
        console.debug('Offline request sync finished', requests);
      });
  }
}

export function InitRequestSyncService(service: RequestSyncService) {
  return () => service.init();
}
