<div class="stil-leftnav flex flex-col justify-start items-center grow h-full">
  <a
    *ngFor="let navItem of enabledRoutes"
    class="nav-block flex flex-row h-[40px]"
    (click)="navigateTo(navItem.route)"
  >
    <div
      *ngIf="!navItem.children"
      class="nav-item p-1 w-[40px] flex flex-row justify-start items-center"
      [tooltip]="'RIGHT_NAV.' + navItem.name | translate"
      placement="left"
      [adaptivePosition]="false"
    >
      <i class="fas fa-{{ navItem.icon }} w-[25px]"></i>
    </div>

    <div
      class="nav-item flex flex-row justify-center items-center w-[40px]"
      *ngIf="navItem.children"
      [tooltip]="'RIGHT_NAV.' + navItem.name | translate"
      placement="left"
      [adaptivePosition]="false"
      [matMenuTriggerFor]="menu"
    >
      <i class="child-icon fas fa-{{ navItem.icon }}"></i>
    </div>

    <mat-menu #menu="matMenu">
      <a
        mat-menu-item
        *ngFor="let link of navItem.children"
        [disabled]="link?.disabled"
        [routerLink]="link.route"
      >
        <i class="menu-icon fas fa-{{ link.icon }} w-[25px]"></i>
        <span>{{ 'RIGHT_NAV.' + link.name | translate }}</span>
      </a>
    </mat-menu>
  </a>
  <div class="flex flex-col justify-end items-center grow">
    <i
      (click)="showSettings()"
      class="fas fa-gears h-[25px]"
    ></i>
  </div>
</div>
