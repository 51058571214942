import {
  animate, state, style, transition, trigger 
} from '@angular/animations';
import { CommonModule } from '@angular/common';
import {
  Component, EventEmitter, inject, Input, OnInit, Output 
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { QUILL_CONFIG_TOKEN, QuillModule } from 'ngx-quill';
import { timer } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Dashlet } from 'src/app/dashboards/models/dashlet.model';
import { DashletPermisions } from 'src/app/dashboards/models/dashletPermissions';
import { DashletsService } from 'src/app/dashboards/services/dashlets/dashlets.service';
import { showHideVertical } from '~animations/show-hide-vertical.animation';

import { AnnouncementsService } from '../../services/announcements/announcements.service';

@UntilDestroy()
@Component({
  selector: 'app-announcement-dashlet',
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    QuillModule
  ],
  templateUrl: './announcement-dashlet.component.html',
  styleUrls: [ './announcement-dashlet.component.scss' ],
  animations: [
    trigger('openClose', [
      state('true', style({ height: '*' })),
      state('false', style({
        height: '0px',
        padding: '0px'
      })),
      transition('false <=> true', animate(500))
    ]),
    showHideVertical()
  ]
})
export class AnnouncementDashletComponent implements OnInit {

  private config = inject(QUILL_CONFIG_TOKEN);
  private announcementsSvc = inject(AnnouncementsService);
  private dashletsSvc = inject(DashletsService);

  @Input() dashlet: Dashlet | null;
  @Input() dashletPermissions: DashletPermisions = {};
  @Output() dashletDeleted = new EventEmitter<number>();

  announcements: any = [];
  currentAnnouncementIndex = 0;
  rxjsTimer = timer(5000, 5000);
  hover = false;
  showContent = true;

  get currentAnnouncement(): any {
    return this.announcements?.[this.currentAnnouncementIndex];
  }

  ngOnInit(): void {
    this.announcementsSvc.getAnnouncementsForToday()
      .pipe(untilDestroyed(this))
      .subscribe(announcements => {
        this.announcements = announcements;
      });
    this.rxjsTimer
      .pipe(
        untilDestroyed(this),
        filter(() => !this.hover)
      )
      .subscribe(this.onNextClick.bind(this));
  }

  deleteDashlet(): void {
    this.dashletsSvc.deleteDashlet(this.dashlet.ID).subscribe(res =>{
      this.dashletDeleted.emit(this.dashlet.ID)
    })
  }

  onPreviousClick() {
    const previous = this.currentAnnouncementIndex - 1;
    this.currentAnnouncementIndex = previous < 0 ? this.announcements.length - 1 : previous;
  }

  onNextClick() {
    const next = this.currentAnnouncementIndex + 1;
    this.currentAnnouncementIndex = next === this.announcements.length ? 0 : next;
  }

  onMouseEnter(eventObject) {
    this.hover = true;
  }
  onMouseLeave(eventObject) {
    this.hover = false;
  }

  toggleContent(): void {
    this.showContent = !this.showContent;
  }
}
