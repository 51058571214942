import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Dictionary } from 'lodash';
import { TranslationsState } from '~translations/state/translations.state';

@UntilDestroy()
@Component({
  selector: 'app-nav-link',
  templateUrl: './nav-link.component.html',
  styleUrls: [ './nav-link.component.scss' ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavLinkComponent implements OnInit {

  @Input() link: Dictionary<any>;

  constructor(
    private cdr: ChangeDetectorRef,
    private translationState: TranslationsState
  ) { }

  ngOnInit() {
    this.translationState.activeLanguage$
      .pipe(untilDestroyed(this))
      .subscribe(() => this.cdr.markForCheck());
  }

  isRecentPermitLink(): boolean {
    return /\[[0-9]+\]/ig.test(this.link?.Name);
  }

  isLastSectionLink(linkName: string): boolean {
    return [ 'Permit Index V2', 'Standard Index V2' ]
      .map(link => link.toLowerCase())
      .includes(linkName.toLowerCase());
  }
}
