import { Injectable } from '@angular/core';
import {
  compact, Dictionary, get, head, merge, tail, values
} from 'lodash';
import { FacilityState } from '~core/states/facility/facility.state';

export interface SettingsPathObject {
  isStandard: boolean;
  feature: Feature;
  permitType: string;
  varietyTypeId?: number;
  statusId?: number;
  actionId?: number;
  actionStatusId?: number;
}

export type Feature = 'create' | 'edit' | 'index' | 'permitStatus' | 'actionStatus' | 'safetkonnect' | 'datafilterGroup';

export const FEATURE_NAME_MAP = {
  actionStatus: 'PermitStatusActionStatus',
  create: 'CreatePermit',
  edit: 'EditPermit',
  index: 'PermitIndex',
  permitStatus: 'PermitStatus',
  safetkonnect: 'SafeTKonnect',
  dataFilterGroup: 'DataFilterGroup'
};

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private facilityState: FacilityState
  ) { }

  getFilteredSettings(pathObj: SettingsPathObject, withChildren = false): Dictionary<any> {
    const {
      isStandard,
      feature,
      permitType,
      ...settingIds
    } = pathObj;

    const settingsName = isStandard ? 'standardSettings' : 'permitSettings';
    const typeSettings = this.facilityState.get(settingsName)[FEATURE_NAME_MAP[feature]][permitType] ?? {};

    const activeIds = compact(values(settingIds));

    if (activeIds?.length) {
      const varietyTypeId = head(activeIds);
      const statusIds = tail(activeIds);

      const { settings: varietyTypeSettings, ...statusSettings } = typeSettings?.[varietyTypeId] ?? {};

      const { settings: activeStatusSettings, ...childStatusNodes } = get(statusSettings, [ ...statusIds ], {});

      const filteredSettings = merge({}, varietyTypeSettings, activeStatusSettings);

      if (withChildren) {
        return {
          settings: filteredSettings,
          ...childStatusNodes
        };
      }

      return filteredSettings;

    } else {
      if (withChildren) {
        return typeSettings;
      }

      return typeSettings?.settings ?? {};
    }
  }

}
