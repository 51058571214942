import {
  Inject, Injectable, Injector
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { castArray } from 'lodash';
import { DateTime } from 'luxon';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ToastrService } from 'ngx-toastr';
import {
  Observable, of, Subscription, timer
} from 'rxjs';
import {
  catchError, switchMap, take, takeWhile, tap
} from 'rxjs/operators';
import { NetworkState } from '~auth/states/network-state/network.state';
import { WINDOW } from '~core/services/window/window.service';
import { AppState } from '~core/states/app/app.state';
import { PermissionsState } from '~permissions/state/permissions.state';
import { LoginControllerService } from '~shared/services/app-services/apiLoginAppController';

import { PasswordResetDialogComponent } from '../password-reset-dialog/password-reset-dialog.component';
import { AuthState } from '../states/auth.state';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  refreshTimerSub: Subscription;

  constructor(
    private appState: AppState,
    private indexedDB: NgxIndexedDBService,
    private loginSvc: LoginControllerService,
    private networkState: NetworkState,
    private router: Router,
    private state: AuthState,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private permissionsState: PermissionsState,
    @Inject(WINDOW) private window: Window
  ) {}

  getUserRoles() {
    return [ 'admin', 'user' ];
  }

  isAuthenticated(): boolean {
    return this.state.get('isLoggedIn');
  }

  async init() {
    if (!navigator.cookieEnabled) {
      this.toastr.warning('Access Denied to sessionStorage, try enabling Cookies');
    }

    const navigationType = (this.window.performance.getEntriesByType("navigation")?.[0] as any)?.type;

    if (navigationType == 'back_forward'){
      this.removeTokens();
    }

    let exp = +sessionStorage.getItem('exp');
    let token = sessionStorage.getItem('token');
    let refreshToken = sessionStorage.getItem('refreshToken');
    let user = sessionStorage.getItem('user');

    const offlineToken: any = await this.indexedDB.getByKey('auth', 1).toPromise();

    if (!this.networkState.get('isOnline') && offlineToken && (!user || !token)) {
      exp = offlineToken?.exp;
      token = offlineToken?.token;
      refreshToken = offlineToken?.refreshToken;
      user = offlineToken?.user;
    }

    if (user && token) {
      this.state.set('exp', +exp);
      this.state.set('token', token);
      this.state.set('refreshToken', refreshToken);
      await this.refreshToken(refreshToken);

      if (!this.appState.get('connectionStringName')) {
        this.appState.set('connectionStringName', localStorage.getItem('lastConnectionStringName'));
      }
    } else {
      this.removeTokens();
    }
  }

  isAuthorized(accessRoles: string | string[] = []) {
    accessRoles = castArray(accessRoles);

    return true;
  }

  login(connectionStringName: string, facilityId: number, username: string, password: string): Observable<any> {
    console.info('Login call started...');
    return this.loginSvc.login(connectionStringName, facilityId, username, password)
      .pipe(
        tap(() => {
          localStorage.setItem('lastConnectionStringName', connectionStringName);
          this.appState.set('connectionStringName', connectionStringName);
        }),
        catchError(error => {
          console.error(`Login Error: ${error?.message}`);
          this.toastr.error('Authorization Failed');
          return of(false);
        })
      );
  }

  logout(): Observable<boolean> {
    console.log('logout call initiated...');

    this.dialog.closeAll();

    return this.loginSvc.logout()
      .pipe(
        switchMap(() => {
          this.removeTokens();
          console.log(`logout call finished at ${DateTime.local().toJSDate()}.\n Redirecting to login...`);
          this.state.set('loggedOut',true);
          return this.router.navigateByUrl('/login');
        }),
        catchError(err => {
          this.removeTokens();
          console.warn(`logout call FAILED at ${DateTime.local().toJSDate()}.\n Redirecting to login...`);
          this.state.set('loggedOut',true);
          return this.router.navigateByUrl('/login');
        })
      );
  }

  openResetPasswordDialog(): Observable<boolean> {
    const dialogOptions = {
      width: '640px',
      hasBackdrop: true
    };

    return this.dialog.open(PasswordResetDialogComponent, dialogOptions)
      .afterClosed();
  }

  redirectUser(): void {
    console.log('redirecting user...');
    const redirect = sessionStorage.getItem('redirect');

    if (redirect) {
      this.router.navigateByUrl(redirect);
      sessionStorage.removeItem('redirect');
    } else {
      this.router.navigateByUrl('/');
    }
  }

  async refreshToken(refreshToken = this.state.get('refreshToken')): Promise<any> {
    this.state.set('tokenRefreshing', true);
    let authToken: any;

    if (!this.networkState.get('isOnline')) {
      authToken = await this.indexedDB.getByKey('auth', 1).toPromise();
    } else {
      authToken = await this.loginSvc.refreshToken(refreshToken).toPromise();
    }

    return this.setSession(authToken);
  }

  removeTokens() {
    /* TODO: replace sessionStorage with InjectionToken */
    sessionStorage.clear();
    this.permissionsState.set('allPermissions', null);
    this.state.set('exp', null);
    this.state.set('token', null);
    this.state.set('refreshToken', null);
    this.state.set('loggedOut',false);
    this.appState.set('user', null);
    this.state.set('isLoggedIn', false);
    this.indexedDB.clear('auth');
  }

  private setRefreshTimer(): void {
    let tokenExpirationDateTime = DateTime.fromSeconds(this.state.get('exp')).toJSDate();

    if (this.refreshTimerSub) {
      console.debug('refresh timer existed, unsubscribing');
      this.refreshTimerSub.unsubscribe();
    }

    console.debug(`User Token refreshed at: ${DateTime.local().toFormat('hh:mm:ss a ZZZZ') }`);
    console.debug(`User Token will expire at: ${DateTime.fromJSDate(tokenExpirationDateTime).toFormat('hh:mm:ss a ZZZZ')}`);
    console.debug(`User OFFLINE Token will expire at: ${DateTime.fromJSDate(tokenExpirationDateTime).plus({ days: 1 })
      .toFormat('mm/dd/yyyy hh:mm:ss a ZZZZ')}`);

    if (!this.networkState.get('isOnline')) {
      console.debug('User is OFFLINE');
      tokenExpirationDateTime = DateTime.fromJSDate(tokenExpirationDateTime)
        .plus({ days: 1 })
        .toJSDate();
    }

    this.refreshTimerSub = timer(tokenExpirationDateTime)
      .pipe(
        takeWhile(() => this.state.get('isLoggedIn') === true),
        switchMap(() => this.refreshToken()),
        take(1)
      )
      .subscribe(() => console.debug('Token Refreshed'));
  }

  async setSession({
    exp, expRefresh, token, refreshToken, user
  }: any): Promise<void> {
    if (exp && expRefresh && token && refreshToken) {
      this.state.set('exp', +exp);
      this.state.set('expRefresh', +expRefresh);
      this.state.set('token', token);
      this.state.set('refreshToken', refreshToken);
      this.state.set('isLoggedIn', true);
      this.state.set('loggedOut', false);

      if (!user) {
        user = this.appState.get('user');
      }

      await this.indexedDB.update('auth', {
        id: 1,
        exp,
        expRefresh,
        token,
        refreshToken,
        user
      }).toPromise();

      this.setRefreshTimer();
      this.state.set('tokenRefreshing', false);
    } else {
      this.removeTokens();
      this.toastr.error(`Attempted to set invalid authentication token`, 'Invalid Token');
    }
  }
}

export function InitializeAuth(injector: Injector): () => void {
  return async () => {
    const authSvc = injector.get(AuthService);
    await authSvc.init();
  };
}
