import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '~shared/shared.module';

import { TranslationsComponent } from './components/translations/translations.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslatePipeModule } from '~shared/pipes/translate.pipe';


@NgModule({
  declarations: [ TranslationsComponent ],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MatTooltipModule,
    TranslatePipeModule
  ]
})
export class TranslationsModule { }
