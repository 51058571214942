import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  countBy,
  pick, sortBy
} from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { SettingsService } from '~core/services/settings/settings.service';
import { AppState } from '~core/states/app/app.state';
import { Dashboard } from '../../models/dashboard';
import { Dashlet } from '../../models/dashlet.model';

@Injectable({
  providedIn: 'root'
})
export class DashletsService {

  private http = inject(HttpClient);

  private _dashletCreated = new Subject<Dashlet>();
  dashletCreated$ = this._dashletCreated.asObservable();

  constructor() { }

  getDashlets(dashboardId: number): Observable<Dashlet[]> {

    const params = new HttpParams({
      fromObject: {
        parentID: dashboardId
      }
    });

    return this.http.get<Dashboard[]>(`dashlets/getrecordsforparentid`, { params });
  }

  createDashlet(dashlet: Dashlet): Observable<Dashlet> {
    return this.http.post<Dashlet>('dashlets', dashlet).pipe(
      tap(newDashlet => this._dashletCreated.next(newDashlet))
    )
  }

  deleteDashlet(dashletId: number): Observable<void> {
    return this.http.delete<void>(`dashlets/dashlet/${dashletId}`);
  }

  editDashlets(dashlet: Dashlet): Observable<Dashlet> {
    return this.http.put<Dashlet>('dashlets/dashlet', dashlet);
  }

  dashletCreated(dashlet: Dashlet): void {
    this._dashletCreated.next(dashlet);
  }

}
