import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppState } from '~core/states/app/app.state';

@Injectable({
  providedIn: 'root'
})
export class GeneralAppDataService {
  constructor(private http: HttpClient, private appState: AppState) { }

  getEnabledPermitNames(): Observable<any[]> {
    const params = { FacilityID: this.appState.get('facilityId') };
    return this.http.get<any[]>(`general/getenabledpermitnames`, { params: params as any });
  }

  getADLogs() {
    const params = { facilityID: this.appState.get('facilityId') };
    return this.http.get(`general/facility/adlog`, { params: params as any });
  }

  getADSync() {
    const params = { facilityID: this.appState.get('facilityId') };
    return this.http.get(`general/facility/adsync`, { params: params as any });
  }
}
