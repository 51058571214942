import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {

  isMobile$: Observable<boolean> = this.breakpointObserver
    .observe([
      Breakpoints.Tablet,
      Breakpoints.Handset,
      '(max-width: 1200px)' 
    ])
    .pipe(map(result => !!result.matches));

  constructor(
    private breakpointObserver: BreakpointObserver
  ) { }

}
