
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Language } from '~translations/models/language.model';

@Injectable({
  providedIn: 'root'
})
export class STIL_LanguageDataService {
  constructor(private http: HttpClient) {}

  create() {
    const params = {};
    return this.http.post(`stilLanguage/create`, {});
  }
  edit(languageID: number) {
    const params = { languageID };
    return this.http.put(`stilLanguage/edit`, {}, { params: params as any });
  }
  delete(languageID: number) {
    const params = { languageID };
    return this.http.delete(`stilLanguage/delete`);
  }
  view(languageID: number) {
    const params = { languageID };
    return this.http.get(`stilLanguage/view`);
  }
  getID(name: string, parentID: number) {
    const params = {
      name,
      parentID 
    };
    return this.http.get(`stilLanguage/getID`);
  }

  getRecordsForParentID(parentID: number, activeRecord: boolean): Observable<Language[]> {
    const params = {
      parentID,
      activeRecord 
    };
    return this.http.get<any[]>(`stilLanguage/getRecordsForParentID`, { params: params as any });
  }
}
