import { Inject, Injectable } from '@angular/core';
import { NetworkState } from '~auth/states/network-state/network.state';
import { WINDOW } from '~core/services/window/window.service';

import { CheckForUpdateService } from '../service-worker/check-for-update/check-for-update.service';
import {
  HandleUnrecoverableStateService
} from '../service-worker/handle-unrecoverable-state/handle-unrecoverable-state.service';
import { LogUpdateService } from '../service-worker/log-update/log-update.service';
import { PromptUpdateService } from '../service-worker/prompt-update/prompt-update.service';

@Injectable({
  providedIn: 'root'
})
export class OfflineService {

  constructor(
    @Inject(WINDOW) private window: Window,
    private networkState: NetworkState,
    private checkForUpdateSvc: CheckForUpdateService,
    private logUpdateSvc: LogUpdateService,
    private promptUpdateSvc: PromptUpdateService,
    private handleUnrecoverableStateSvc: HandleUnrecoverableStateService
  ) {}

  init() {
    this.networkState.set('isOnline', (window?.navigator?.onLine));
    this.window.addEventListener('online', () => this.networkState.set('isOnline', true));
    this.window.addEventListener('offline', () => this.networkState.set('isOnline', false));
  }
}

export function initializeOfflineService(offlineSvc: OfflineService): () => void {
  return () => offlineSvc.init();
}
