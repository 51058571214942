import {
  NgModule, Pipe, PipeTransform 
} from '@angular/core';
import {
  camelCase, get, isEqual, last, snakeCase, startCase, toUpper 
} from 'lodash';
import { TranslationsState } from '~translations/state/translations.state';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {
  private _activeLanguageId: number | false;
  private _path: string[];
  private _translations: any;
  private translation: string;

  constructor(
    private state: TranslationsState
  ) {}

  transform(path: string | string[]): any {
    path = this.sanitizePath(path);
    const activeLanguageId = get(this.state.get('activeLanguage'), 'ID', false);
    const translations = this.state.get('translations');

    const languageChanged = +activeLanguageId !== +this._activeLanguageId;
    const translationsChanged = !isEqual(this._translations, translations);
    const pathChanged  = !isEqual(path, this._path);

    if (languageChanged || translationsChanged || pathChanged) {
      this._activeLanguageId = activeLanguageId;
      this._translations = translations;
      this._path = path;

      this.updateTranslation(path);
    }

    return this.translation;
  }

  private sanitizePath(path: string | string[]): string[] {
    if (typeof path === 'string') {
      path = (path as string).split('.');
    }

    return path.map(key => toUpper(snakeCase(key)));
  }

  private updateTranslation(path = this._path): void {
    let translation = get(this._translations, path);

    if (!translation) {
      translation = get(this.state.get('defaultTranslations'), path, startCase(camelCase(last(path))));
    }

    this.translation = translation;
  }
}

@NgModule({
  declarations: [ TranslatePipe ],
  exports: [ TranslatePipe ]
})
export class TranslatePipeModule {}
