import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  APP_INITIALIZER, Injector, NgModule 
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { SharedModule } from '../shared/shared.module';
import { CanDeactivateGuard } from './guards/can-deactivate.guard';
import { AuthTokenInterceptor } from './interceptors/auth-token.interceptor';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { LoginPageComponent } from './login-page/login-page.component';
import { PasswordResetDialogComponent } from './password-reset-dialog/password-reset-dialog.component';
import { InitializeAuth } from './services/auth.service';
import { SsoPageComponent } from './sso-page/sso-page.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    MatProgressBarModule,
    MatDialogModule,
    TypeaheadModule.forRoot(),
    ReactiveFormsModule
  ],
  providers: [
    CanDeactivateGuard,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: InitializeAuth,
      deps: [ Injector ],
      multi: true
    }
  ],
  declarations: [
    LoginPageComponent,
    PasswordResetDialogComponent,
    SsoPageComponent 
  ],
  exports: [ LoginPageComponent, SsoPageComponent ]
})
export class AuthModule { }
