import {
  inject, Pipe, PipeTransform 
} from '@angular/core';
import findKey from 'lodash/findKey';
import { AppState } from '~core/states/app/app.state';
import { FacilityState } from '~core/states/facility/facility.state';


@Pipe({
  name: 'appPermitTypeIcon',
  standalone: true
})
export class PermitTypeIconPipe implements PipeTransform {

  private state = inject(AppState);
  private facilityState = inject(FacilityState);

  get permitGroupIds(): any {
    return this.state.get('permitGroupIds');
  }

  transform(permitType: string | number): string {
    if (typeof permitType === 'number') {
      permitType = findKey(this.permitGroupIds, { id: permitType });
    }

    return this.facilityState.get('dashboardIcons')?.[permitType] ?? 'fas fa-circle-notch';
  }
}
